import {
  Button,
  Colours,
  Divider,
  ProgressBar,
  Typography,
  renderIcon,
} from '@ecdlink/ui';
import {
  MotherDto,
  getStringFromClassNameOrId,
  getWeeksDiff,
} from '@ecdlink/core';
import { useCallback, useMemo } from 'react';
import { VisitDataStatus } from '@/../../../packages/graphql/lib';
import { progressSteps } from './steps';
import {
  activitiesColours,
  activitiesSectionTypes,
  activitiesTypes,
} from '../activity-list/activities-list';
import {
  InfoCard,
  Item,
} from '../activity-list/forms/components/follow-up/info-card';
import BabyHealthcare from '@/assets/iconCircleAntenatalSmall3.png';
import P1 from '@/assets/pillar/p1.svg';
import P5 from '@/assets/pillar/p5.svg';
import Pregnant from '@/assets/pregnant.svg';
import Infant from '@/assets/infant.svg';
import { useWindowSize } from '@reach/window-size';
import { Header } from '../../components';
import { date } from 'yup';

export interface FollowUpWalkthroughData {
  progressBar: {
    message: string;
    label: string;
    value: number;
    primaryColour: Colours;
    secondaryColour: Colours;
  };
  infoCard: {
    [key: string]: {
      comment: string;
      section: string;
      visitData: { visitName: string };
    }[];
  };
}

interface FollowUpComponentProps {
  mother?: MotherDto;
}

interface Status {
  success: VisitDataStatus[];
  warning: VisitDataStatus[];
  error: VisitDataStatus[];
  none: VisitDataStatus[];
}

type StatusType = keyof Status;

export const ProgressDummy = ({ mother }: FollowUpComponentProps) => {
  const name = useMemo(() => mother?.user?.firstName || '', [mother]);

  const walkthroughData: FollowUpWalkthroughData = {
    progressBar: {
      message: `${mother?.user?.firstName} is doing well!`,
      label: '6/6',
      value: 100,
      primaryColour: 'successMain',
      secondaryColour: 'successBg',
    },
    infoCard: {
      success: [
        {
          comment: `No danger signs for ${mother?.user?.firstName}`,
          section: 'Clinic visits',
          visitData: { visitName: activitiesTypes.dangerSigns },
        },
      ],
    },
  };

  const getColorAndIcon = useCallback(
    (
      color: string
    ): { primaryColour: Colours; secondaryColour: Colours; icon: string } => {
      const formattedColor = color.toLowerCase();
      switch (formattedColor) {
        case 'warning':
          return {
            primaryColour: 'alertMain',
            secondaryColour: 'alertBg',
            icon: 'ExclamationIcon',
          };
        case 'error':
          return {
            primaryColour: 'errorMain',
            secondaryColour: 'errorBg',
            icon: 'ExclamationCircleIcon',
          };
        case 'success':
        default:
          return {
            primaryColour: 'successMain',
            secondaryColour: 'successBg',
            icon: 'BadgeCheckIcon',
          };
      }
    },
    []
  );

  const getVisitIcon = (visitName: string) => {
    switch (visitName) {
      case activitiesSectionTypes.healthCare:
        return {
          icon: BabyHealthcare,
          color: activitiesColours.other.primaryColor,
        };
      case activitiesSectionTypes.nutrition:
        return { icon: P1, color: '#8CDBDF' };
      case activitiesSectionTypes.pregnancyCare:
        return {
          icon: Pregnant,
          color: activitiesColours.pillar1.primaryColor,
        };
      default:
        return { icon: P5, color: activitiesColours.pillar5.primaryColor };
    }
  };

  const diffDates = !!mother?.expectedDateOfDelivery
    ? getWeeksDiff(new Date(), new Date(mother?.expectedDateOfDelivery))
    : '';

  const actualGestationWeek = !!diffDates ? 40 - diffDates : '';

  return (
    <>
      <Header
        backgroundColor="tertiary"
        customIcon={Infant}
        title={
          mother?.user?.firstName ?? `Summary of your last visit with ${name}`
        }
        {...(!!actualGestationWeek
          ? {
              subTitle: `${actualGestationWeek} ${
                actualGestationWeek > 1 ? 'weeks' : 'week'
              }`,
            }
          : {})}
        description={`Your last home visit: ${
          date
            ? new Date(String(date)).toLocaleDateString('en-ZA', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'None'
        }`}
      />
      <div
        className="p-4 pt-8"
        id={getStringFromClassNameOrId(progressSteps[0].target)}
      >
        <div className="flex gap-4 p-4 pt-8">
          <Typography
            className="w-2/4"
            type="h4"
            text={walkthroughData?.progressBar.message}
          />
          <div className="h-16 w-2/4">
            <ProgressBar
              label={walkthroughData?.progressBar.label || ''}
              subLabel="score"
              value={walkthroughData?.progressBar.value || 1}
              primaryColour={walkthroughData?.progressBar.primaryColour}
              secondaryColour={walkthroughData?.progressBar.secondaryColour}
            />
          </div>
        </div>
      </div>

      <Divider dividerType="dashed" className="my-8" />

      <Typography
        className="mb-8"
        type="h4"
        text={`Here is a summary of how ${name} is doing:`}
      />

      <Divider dividerType="dashed" className="mt-4 mb-8" />

      <div id={getStringFromClassNameOrId(progressSteps[1].target)}>
        {!!walkthroughData.infoCard &&
          Object.keys(walkthroughData.infoCard).map((item, index) => {
            const { icon, primaryColour, secondaryColour } =
              getColorAndIcon(item);

            const dataByStatus = walkthroughData.infoCard[item as StatusType];
            const uniqueData = dataByStatus.filter((object, index, array) => {
              return (
                index ===
                array.findIndex(
                  (newObject) => newObject.comment === object.comment
                )
              );
            });

            return (
              <InfoCard
                key={index}
                className="my-6"
                icon={icon}
                items={uniqueData.map((data): Item => {
                  const { icon, color } = getVisitIcon(data?.section || '');
                  return {
                    customIcon: icon,
                    iconHexBackgroundColour: color,
                    title: `${data.comment}`,
                  };
                })}
                primaryColour={primaryColour}
                secondaryColour={secondaryColour}
              />
            );
          })}
      </div>

      <div className="flex h-full flex-col gap-4 px-4">
        <>
          <Button
            className="mt-auto"
            type="filled"
            color="primary"
            textColor="white"
            text="Download client copy"
            icon="SaveIcon"
          />
          <Button
            type="outlined"
            color="primary"
            textColor="primary"
            text="Manage referrals"
            icon="ClipboardListIcon"
          />
        </>
      </div>
    </>
  );
};
