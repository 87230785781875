import { clinicCheckupSectionName } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/care-for-mom-steps/clinic-check-ups';
import { maternalDistressVisitSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/care-for-mom-steps/maternal-distress-screening';
import { DevelopmentalScreeningVisitSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/pillar-2-steps/developmental-screening-weeks';
import { childDocumentSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/pillar-5-steps/child-documentation';
import { HIVSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/pillar-5-steps/hiv-care-and-medication';
import { activitiesTypes } from '@/pages/infant/infant-profile/progress-tab/activity-list/activities-list';
import { useAppDispatch } from '@/store';
import { infantThunkActions } from '@/store/infant';
import { getInfantLastVisitSelector } from '@/store/infant/infant.selectors';
import { visitThunkActions } from '@/store/visit';
import { VisitDto } from '@ecdlink/core';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/types';

export const useInfantInfo = ({
  infantId,
  visitId,
  currentVisit,
  previousVisit,
}: {
  infantId: string;
  visitId?: string;
  currentVisit?: VisitDto | undefined;
  previousVisit?: VisitDto | undefined;
}) => {
  const appDispatch = useAppDispatch();

  const previousCurrentVisit = useSelector((state: RootState) =>
    getInfantLastVisitSelector(state, infantId)
  );

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(
        visitThunkActions.getCompletedVisitsForVisitId({
          visitId,
        })
      ).unwrap();
      if (
        !!previousCurrentVisit &&
        previousCurrentVisit?.id !== currentVisit?.id &&
        !!currentVisit
      ) {
        appDispatch(
          visitThunkActions.getPreviousVisitInformationForInfant({
            visitId: previousCurrentVisit?.id,
          })
        ).unwrap();
      } else {
        if (!previousCurrentVisit && !!currentVisit) {
          appDispatch(
            visitThunkActions.getPreviousVisitInformationForInfant({
              visitId: currentVisit?.id,
            })
          );
        }
      }
      appDispatch(
        visitThunkActions.GetInfantSummaryByPriority({
          visitId,
        })
      );
    }
  }, [visitId, appDispatch, previousCurrentVisit, currentVisit]);

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(infantThunkActions.getInfantVisits({ infantId })).unwrap();
      appDispatch(
        visitThunkActions.getGrowthDataForInfant({ infantId })
      ).unwrap();
    }
  }, [appDispatch, infantId, visitId]);

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(
        visitThunkActions.getVisitAnswersForInfant({
          visitId,
          visitName: activitiesTypes.pillar2,
          visitSection: DevelopmentalScreeningVisitSection,
        })
      );
    }
  }, [visitId, appDispatch]);

  useLayoutEffect(() => {
    if (previousVisit?.id) {
      appDispatch(
        visitThunkActions.getVisitAnswersForInfant({
          visitId: previousVisit.id,
          visitName: activitiesTypes.careForMom,
          visitSection: clinicCheckupSectionName,
        })
      ).unwrap();
      appDispatch(
        visitThunkActions.getVisitAnswersForInfant({
          visitId: previousVisit.id,
          visitName: activitiesTypes.pillar5,
          visitSection: childDocumentSection,
        })
      ).unwrap();
      appDispatch(
        visitThunkActions.getVisitAnswersForInfant({
          visitId: previousVisit.id,
          visitName: activitiesTypes.pillar5,
          visitSection: HIVSection,
        })
      ).unwrap();
      appDispatch(
        visitThunkActions.getVisitAnswersForInfant({
          visitId: previousVisit.id,
          visitName: activitiesTypes.careForMom,
          visitSection: maternalDistressVisitSection,
        })
      ).unwrap();
    }
  }, [previousVisit, appDispatch]);
};
